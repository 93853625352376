exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beer-tsx": () => import("./../../../src/pages/beer.tsx" /* webpackChunkName: "component---src-pages-beer-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-food-tsx": () => import("./../../../src/pages/food.tsx" /* webpackChunkName: "component---src-pages-food-tsx" */),
  "component---src-pages-happy-hour-tsx": () => import("./../../../src/pages/happy-hour.tsx" /* webpackChunkName: "component---src-pages-happy-hour-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-private-events-tsx": () => import("./../../../src/pages/private-events.tsx" /* webpackChunkName: "component---src-pages-private-events-tsx" */),
  "component---src-pages-wine-cocktails-tsx": () => import("./../../../src/pages/wine-cocktails.tsx" /* webpackChunkName: "component---src-pages-wine-cocktails-tsx" */)
}

